import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Paper, MenuList, MenuItem, ListItemText } from '@mui/material'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import './index.css'

function ProgramSettingsMenu ({ programSettingsMenu, setProgramSettingsMenu, program, useOutsideAlerter, isPublisher }) {
  const navigate = useNavigate()
  const wrapperRef = useRef(null)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setProgramSettingsMenu })

  return (
    <div className="program-settings-menu-container">
      <div onClick={programSettingsMenu === program?.id ? () => setProgramSettingsMenu('') : () => setProgramSettingsMenu(program?.id)}>
        <MoreHorizIcon/>
      </div>
      {programSettingsMenu === program?.id &&
        <div className="program-settings-menu-dropdown-container">
          <Paper
            sx={{ width: 175 }}
            elevation={24}>
            <MenuList dense ref={wrapperRef}>
              {isPublisher && <MenuItem
                onClick={() => navigate(`/programs/${program?.id}/draft`)}>
                <ListItemText>Edit Program</ListItemText>
              </MenuItem>}
            </MenuList>
          </Paper>
        </div>
      }
    </div>
  )
}

export default ProgramSettingsMenu
