import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useModal } from '../context/ModalContext'
import { logError } from './logger'
import {
  getUser,
  postPromoCode,
  putPromoCode,
  getOrganizationPromoCodes,
  getOrganizationSubscription,
  getPublisherPrograms,
  getPublisherProgram,
  getPublicPrograms,
  postProgram,
  putProgram,
  addOrganizationProgram,
  removeOrganizationProgram,
  getCourse,
  postCourse,
  putCourse
} from './api'

function useUserQuery ({ organizationId, options }) {
  return useQuery(['user'], () => getUser(organizationId), options)
}

function useProgramsQuery ({ publisherOrganizationId, options }) {
  return useQuery(['publishers', publisherOrganizationId, 'programs'], () => getPublisherPrograms(publisherOrganizationId), options)
}

function useProgramQuery ({ publisherOrganizationId, programId, options }) {
  return useQuery(['publishers', publisherOrganizationId, 'program'], () => getPublisherProgram({ publisherOrganizationId, programId }), options)
}

function usePublicProgramsQuery () {
  return useQuery(['public_programs'], getPublicPrograms)
}

function useOrganizationPromoCodeQuery ({ organizationId, options }) {
  return useQuery(['organizations', organizationId, 'promoCodes'], () => getOrganizationPromoCodes(organizationId), options)
}

function useOrganizationSubscriptionQuery ({ organizationId, options }) {
  return useQuery(['organizations', organizationId, 'subscription'], () => getOrganizationSubscription(organizationId), options)
}

function useCreatePromoCodeMutation () {
  const queryClient = useQueryClient()
  return useMutation(postPromoCode, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['organizations', response?.createdBy, 'promoCodes'])
      const previousPromoCodes = queryClient.getQueryData(['organizations', response?.createdBy, 'promoCodes'])
      const updatedPromoCodesList = [...previousPromoCodes, response]
      queryClient.setQueryData(['organizations', response?.createdBy, 'promoCodes'], updatedPromoCodesList)
      queryClient.invalidateQueries({ queryKey: ['organizations', response?.createdBy, 'subscription'] })
      return { previousPromoCodes }
    },
    onError: (error, response, context) => {
      logError(error)
      queryClient.setQueryData(['organizations', response?.createdBy, 'promoCodes'], context.previousPromoCodes)
    }
  })
}

function useUpdatePromoCodeMutation () {
  const { clearModal } = useModal()
  const queryClient = useQueryClient()
  return useMutation(putPromoCode, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['organizations', response?.createdBy, 'promoCodes'])
      const previousPromoCodes = queryClient.getQueryData(['organizations', response?.createdBy, 'promoCodes'])
      const updatedPromoCodes = previousPromoCodes?.map(promoCode =>
        promoCode?.id === response?.id
          ? response
          : promoCode)

      queryClient.setQueryData(['organizations', response?.createdBy, 'promoCodes'], updatedPromoCodes)
      queryClient.invalidateQueries({ queryKey: ['organizations', response?.createdBy, 'subscription'] })
      clearModal()
      return { previousPromoCodes }
    },
    onError: (error, response, context) => {
      logError(error)
      queryClient.setQueryData(['organizations', response?.createdBy, 'promoCodes'], context.previousPromoCodes)
    }
  })
}

function useCreateProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(postProgram, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['publishers', response?.organizationId, 'programs'])
      queryClient.invalidateQueries({ queryKey: ['publishers', response?.organizationId, 'programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

function useUpdateProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(putProgram, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['publishers', response?.publisherOrganizationId, 'programs'])
      queryClient.invalidateQueries({ queryKey: ['publishers', response?.publisherOrganizationId, 'programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

function useAddOrganizationProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(addOrganizationProgram, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['public_programs'])
      queryClient.invalidateQueries({ queryKey: ['public_programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

function useRemoveOrganizationProgramMutation () {
  const queryClient = useQueryClient()
  return useMutation(removeOrganizationProgram, {
    onSuccess: async () => {
      await queryClient.cancelQueries(['public_programs'])
      queryClient.invalidateQueries({ queryKey: ['public_programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

function useCourseQuery ({ publisherOrganizationId, courseId, options }) {
  return useQuery(['course', courseId], () => getCourse(courseId), options)
}

function useCreateCourseMutation () {
  const queryClient = useQueryClient()
  return useMutation(postCourse, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['public_programs'])
      queryClient.invalidateQueries({ queryKey: ['public_programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

function useUpdateCourseMutation () {
  const queryClient = useQueryClient()
  return useMutation(putCourse, {
    onSuccess: async (response) => {
      await queryClient.cancelQueries(['public_programs'])
      queryClient.invalidateQueries({ queryKey: ['public_programs'] })
    },
    onError: (error) => {
      logError(error)
    }
  })
}

export {
  useUserQuery,
  useCreatePromoCodeMutation,
  useUpdatePromoCodeMutation,
  useOrganizationPromoCodeQuery,
  useOrganizationSubscriptionQuery,
  useProgramsQuery,
  usePublicProgramsQuery,
  useCreateProgramMutation,
  useUpdateProgramMutation,
  useAddOrganizationProgramMutation,
  useRemoveOrganizationProgramMutation,
  useProgramQuery,
  useCourseQuery,
  useCreateCourseMutation,
  useUpdateCourseMutation
}
