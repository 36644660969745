import { useProgramQuery } from '../../shared/queryHooks'
import { useAuthContext } from '../../context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingScreen from '../../components/LoadingScreen'
import { Chip } from '@mui/material'
import { logError } from '../../shared/logger'
import CheckIcon from '@mui/icons-material/CheckRounded'
import SyncIcon from '@mui/icons-material/Sync'
import { getSortedContent } from '../../shared/utilities'
import Logo from '../../components/Logo'
import { useState } from 'react'
import insightsIllustration from '../../assets/insights.svg'
import EmptyHero from '../../components/EmptyHero'
import CourseType from '../../modals/CourseType'
import { useModal } from '../../context/ModalContext'

function Program () {
  const { makeModal } = useModal()
  const { currentUser } = useAuthContext()
  const { id } = useParams()
  const navigate = useNavigate()
  const { isLoading, isError, error, data: program } = useProgramQuery({ publisherOrganizationId: currentUser?.currentOrganizationId, programId: id, options: { enabled: !!currentUser && id !== undefined } })
  const [searchParms, setSearchParms] = useState('')

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
  }

  const handleCreateCourse = () => {
    makeModal({
      modal: (
        <CourseType programId={id} />
      ),
      title: 'Select Course Type'
    })
  }

  const courseItemDisplay = (course) => {
    const isApproved = course?.courseStatus === courseStatus.PUBLIC || course?.courseStatus === courseStatus.PRIVATE || course?.courseVersion?.[0].isApproved
    const activeChip = {
      icon: isApproved ? <CheckIcon /> : <SyncIcon />,
      label: <div className='subscribe-chip'>
        {courseStatus[course?.courseStatus]}
      </div>
    }

    return (
      <div key={course?.id} className='program-card-container' style={{ cursor: 'pointer' }} onClick={() => navigate(`/courses/${course?.id}/draft?programId=${id}`)}>
        <div className="chip-container">
          <Chip {...activeChip} size="small" variant="outlined" sx={{ marginRight: '0.5rem' }}/>
        </div>
        <div className="program-metadata-container">
          <Logo logoUrl={course?.publisherLogoURL} name={course?.name} alt={'program logo'}/>
          <div className="program-metadata">
            <div className="program-name">{course?.name}</div>
            <div className="program-publisher-name">{course?.program?.publisherName}</div>
          </div>
        </div>
      </div>
    )
  }

  const handleSearch = (search) => {
    setSearchParms(search)
  }

  const courseStatus = {
    DRAFT: 'Draft',
    PRIVATE: 'Private',
    PUBLIC: 'Public'
  }

  const action = [{
    onClick: () => handleCreateCourse(),
    actionTitle: 'Create Course'
  }]

  const generateCourseDisplay = (
    program?.courses
      ?.sort((a, b) => {
        const statusOrder = {
          PUBLIC: 0,
          PRIVATE: 1,
          DRAFT: 2
        }
        return statusOrder[a?.courseStatus] - statusOrder[b?.courseStatus]
      })
      .map(course => {
        return courseItemDisplay(course)
      })
  )
  return (
    <>
      <div className='program-header-container'>
        <div className="marketing-title-header">Courses</div>
        <div className="button secondary program-button" onClick={() => handleCreateCourse()}>Create Course</div>
      </div>
      <div className="program-search">
        <input
          className="program-input"
          type="text"
          placeholder="Search Courses..."
          onChange={(e) => handleSearch(e.target.value)}
          value={searchParms}
        />
      </div>
      <div className="programs-container">
        {program?.courses?.length > 0
          ? <div className="program-wrapper">
              {searchParms ? getSortedContent({ searchParms, searchItemDisplay: courseItemDisplay, contents: program.courses }) : generateCourseDisplay}
            </div>
          : <EmptyHero
              image={insightsIllustration}
              title="Courses"
              data-testid="insights-empty-hero"
              description="You don't have any courses yet. Once you have created a course, analytics will appear here."
              action={action}
            />
        }
      </div>
    </>
  )
}

export default Program
