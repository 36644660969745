import { useNavigate } from 'react-router-dom'
import { useRef, useState } from 'react'
import { getActiveStudioAccessOrganizations, getCurrentOrganization, useSwitchOrganization, useOutsideAlerter } from '../../shared/utilities'
import { Paper, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { useAuthContext } from '../../context/AuthContext'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import Check from '@mui/icons-material/CheckRounded'
import Logo from '../Logo'
import './index.css'

function OrganizationSelectionMenu ({ className }) {
  const navigate = useNavigate()
  const [select, setSelect] = useState(false)
  const wrapperRef = useRef(null)
  const { switchOrganization } = useSwitchOrganization()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const activeOrganizations = getActiveStudioAccessOrganizations(currentUser)
  useOutsideAlerter({ ref: wrapperRef, setDisplayState: setSelect })

  function generateNavIcon () {
    const name = currentOrganization?.name || 'Unknown'
    return (
      <>
        <Logo logoUrl={currentOrganization?.logoUrl} name={name} alt={'organization logo'} />
        <h1 className="organization-name">{name}</h1>
      </>
    )
  }

  const selectOrganization = (organizationId) => {
    localStorage.setItem('lastOrganizationId', organizationId)
    switchOrganization(organizationId)
    setSelect(false)
    window.location.reload()
    navigate('/')
  }

  const orgDropdownMenu = activeOrganizations?.map(org => (
    <MenuItem key={org?.id} onClick={() => selectOrganization(org?.id)}>
      {currentUser?.currentOrganizationId === org?.id ? <><ListItemIcon><Check /></ListItemIcon> {org?.name}</> : <ListItemText inset>{org?.name}</ListItemText>}
    </MenuItem>
  ))

  if (!currentOrganization) {
    return null
  }

  return (
    <div ref={wrapperRef} className={className}>
      <div className="studio-nav-title" onClick={() => setSelect(!select)}>
        {generateNavIcon()}
        <span style={{ marginTop: '0.25rem' }}><ExpandMoreRoundedIcon /></span>
      </div>
      {select && <div className='studio-dropdown'>
        <Paper
          sx={{ width: 250 }}
          elevation={24}>
          <MenuList>
            <MenuItem disabled>Organizations</MenuItem>
            {orgDropdownMenu}
          </MenuList>
        </Paper>
      </div>}
    </div>
  )
}

export default OrganizationSelectionMenu
