import { API } from 'aws-amplify'

async function getUser (organizationId) {
  const user = await API.get('ENGINEER_KIT_API', '/user')
  const existingOrganizationId =
    parseInt(organizationId) ||
    parseInt(localStorage.getItem('lastOrganizationId'))

  return {
    ...user,
    currentOrganizationId:
      existingOrganizationId || user?.organizationRoles[0]?.organization?.id
  }
}

async function getOrganizationPromoCodes (organizationId) {
  return await API.get(
    'ENGINEER_KIT_API',
    `/organizations/${organizationId}/promo_codes`
  )
}

async function postPromoCode (body) {
  return await API.post(
    'ENGINEER_KIT_API',
    `/organizations/${body.createdBy}/promo_code`,
    { body }
  )
}

async function putPromoCode (body) {
  return await API.put(
    'ENGINEER_KIT_API',
    `/organizations/${body?.organizationId}/promo_code`,
    { body }
  )
}

async function getOrganizationSubscription (organizationId) {
  return await API.get(
    'ENGINEER_KIT_API',
    `/organizations/${organizationId}/subscription`
  )
}

async function getPublisherPrograms (publisherOrganizationId) {
  return await API.get(
    'ENGINEER_KIT_API',
    `/publishers/${publisherOrganizationId}/programs`
  )
}

async function getPublisherProgram ({ publisherOrganizationId, programId }) {
  return await API.get(
    'ENGINEER_KIT_API',
    `/publishers/${publisherOrganizationId}/programs/${programId}`
  )
}

async function getPublicPrograms () {
  return await API.get('ENGINEER_KIT_API', '/programs')
}

async function postProgram (body) {
  return await API.post('ENGINEER_KIT_API', '/programs', { body })
}

async function putProgram (body) {
  return await API.put('ENGINEER_KIT_API', `/programs/${body?.id}`, {
    body
  })
}

async function addOrganizationProgram (body) {
  return await API.post('ENGINEER_KIT_API', '/organization_programs', { body })
}

async function removeOrganizationProgram (body) {
  return await API.del('ENGINEER_KIT_API', '/organization_programs', { body })
}

async function getCourse (courseId) {
  return await API.get('ENGINEER_KIT_API', `/publisher_courses/${courseId}`)
}

async function postCourse (body) {
  return await API.post('ENGINEER_KIT_API', '/courses', { body })
}

async function putCourse (body) {
  return await API.put('ENGINEER_KIT_API', `/courses/${body?.id}`, { body })
}

export {
  getUser,
  postPromoCode,
  putPromoCode,
  getOrganizationPromoCodes,
  getOrganizationSubscription,
  getPublisherPrograms,
  getPublicPrograms,
  postProgram,
  putProgram,
  addOrganizationProgram,
  removeOrganizationProgram,
  getPublisherProgram,
  getCourse,
  postCourse,
  putCourse
}
