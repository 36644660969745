import { useAuthContext } from '../../context/AuthContext'
import { Link, NavLink } from 'react-router-dom'
import { isAuthorized } from '../../shared/permissions'
import { getOrganizationAdminPolicy } from '../../shared/policies'
import AvatarSignOut from '../AvatarSignOut'
import OrganizationSelectionMenu from '../OrganizationSelectionMenu'
import './index.css'

function Nav () {
  const { currentUser } = useAuthContext()
  const isAdminOrOwner = isAuthorized({ policy: getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser })
  const currentOrg = (currentUser?.organizationRoles.find(role => role?.organization?.id === currentUser?.currentOrganizationId))?.organization
  const hasStudioAccess = currentOrg?.studioAccess
  const isContentCreator = currentOrg?.isContentCreator
  const hasOrganizationAndRoleStudioAccess = isAdminOrOwner && hasStudioAccess

  return (
    <div className='navigation'>
      <div className="nav-log-organization-selection-container">
        {(currentUser && hasOrganizationAndRoleStudioAccess) && <OrganizationSelectionMenu/>}
        <Link className='connectbetter-logo' to="/">Studio<span className="tag">beta</span></Link>
      </div>
      {(currentUser && hasOrganizationAndRoleStudioAccess)
        ? <>
            <div className="nav-item-container mobile">
              {isContentCreator ? <NavLink className='nav-item' to="/marketing">Marketing</NavLink> : <></>}
              <NavLink className='nav-item' to="/programs">Programs</NavLink>
            </div>
            <div className="sign-out mobile"><AvatarSignOut/></div>
          </>
        : <Link to="/login" className='login'>Login</Link>
      }
    </div>
  )
}

export default Nav
