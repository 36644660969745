import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from '../context/AuthContext'
import { Amplify } from 'aws-amplify'
import { config } from '../shared/config'
import { isAuthorized } from '../shared/permissions'
import { getOrganizationAdminPolicy } from '../shared/policies'
import Login from '../pages/Login'
import ErrorPage from '../pages/ErrorPage'
import LoadingScreen from '../components/LoadingScreen'
import Layout from '../pages/Layout'
import Marketing from '../pages/Marketing'
import Programs from '../pages/Programs'
import CreateOrEditCourse from '../pages/CreateOrEditCourse'
import Program from '../pages/Program'
import SSO from '../pages/SSO'
import Register from '../pages/Register'
import './index.css'
Amplify.configure(config.AWS)

function App () {
  const { isLoading: isAuthLoading, currentUser, firebaseToken, isInitialLoading: isCurrentUserLoading, isSigningOut } = useAuthContext()

  if (isAuthLoading || isCurrentUserLoading) {
    return <LoadingScreen />
  }
  const isAdminOrOwner = isAuthorized({ policy: getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser })
  const currentOrg = (currentUser?.organizationRoles.find(role => role?.organization?.id === currentUser?.currentOrganizationId))?.organization
  const hasStudioAccess = currentOrg?.studioAccess
  const isContentCreator = currentOrg?.isContentCreator
  const hasOrganizationAndRoleStudioAccess = isAdminOrOwner && hasStudioAccess

  if (!!firebaseToken && !!currentUser && hasOrganizationAndRoleStudioAccess) {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={ isContentCreator ? <Marketing/> : <Programs /> } />
          <Route path="/sso" element={<Navigate to="/" replace />} />
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route path="/marketing" element={ <Marketing/> } />
          <Route path="/programs" element={ <Programs/> } />
          <Route path="/programs/:id" element={ <Program/> } />
          <Route path="/courses/:id/draft" element={ <CreateOrEditCourse/> } />
          <Route path="/courses/draft" element={ <CreateOrEditCourse/> } />
          <Route path="*" element={ <ErrorPage message="404, this page does not exist" />} />
        </Route>
      </Routes>
    )
  }

  if (!!firebaseToken && !currentUser) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/register" replace />} />
        <Route path="/register" element={<Register />} />
        <Route path="/sso" element={<Navigate to="/register" replace />} />
        <Route path="*" element={<Navigate to="/register" replace />} />
      </Routes>
    )
  }

  if (!isSigningOut) {
    return (
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={ <Login/> } />
          <Route path="/sso" element={ <SSO />}/>
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
      </Routes>
    )
  }

  return <></>
}

export default App
