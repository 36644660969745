import './index.css'
import { config } from '../../shared/config'

const Register = () => {
  return (
    <div className="register-container-wrapper">
      <div className="register-container">
        <div className="title">Welcome!</div>
        <div className='register-container-text'>It appears that you do not have a ConnectBetter account.</div>
        <div className='register-container-text'>You cannot access ConnectBetter Studio without an active account on ConnectBetter.</div>
        <div className="register-action-container">
          <div></div>
          <a className="button" href={config.CONNECTBETTER.ENDPOINT}>Go to ConnectBetter</a>
        </div>
      </div>
    </div>
  )
}

export default Register
