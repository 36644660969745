import { logWarn } from './logger'

const stage = process.env.REACT_APP_STAGE

const addFirebaseAuthToken = async () => {
  const userAccessToken = localStorage.getItem('firebaseToken')
  try {
    if (userAccessToken) {
      const organizationId = localStorage.getItem('lastOrganizationId')
      return { authorization: userAccessToken, organizationId }
    }
  } catch (error) {
    logWarn(error)
    return {}
  }
}

const CONFIG_PROD = {
  AWS: {
    API: {
      endpoints: [
        {
          name: 'ENGINEER_KIT_API',
          endpoint: 'https://api.connectbetter.io',
          custom_header: addFirebaseAuthToken
        }
      ]
    }
  },
  SENTRY: {
    DSN: 'https://692b9a62a715421ebcc541b35307efb6@o1254450.ingest.sentry.io/4504679288995840'
  },
  GOOGLE: {
    API_KEY: 'AIzaSyD7MZCdtA-611D7vWj0Y7pHhr4nqTmwwa4'
  },
  CRISP: {
    WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40'
  },
  STRIPE: {
    API_KEY: 'pk_live_51LuLuGDGl4W5zqduyGL3qRtuNdJh4Z3CCzj0FMeJRGBmQXLHYt5abC9qvY9QvsccVBc40PeQfxF3Nod9b6qP58oT00rdcIJyqy'
  },
  CONNECTBETTER_API: {
    WEBSOCKET_HOST: 'wss://api.connectbetter.io',
    CDN_HOST: 'https://cdn.connectbetter.io'
  },
  POSTHOG: {
    API_KEY: 'phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH'
  },
  STUDIO: {
    ENDPOINT: 'https://studio.connectbetter.io'
  },
  CONNECTBETTER: {
    ENDPOINT: 'https://app.connectbetter.io'
  },
  IDENTITY: {
    AVODA_IDENTITY_URL: 'https://identity.avoda.co'
  }
}

const CONFIG_STAGING = {
  AWS: {
    API: {
      endpoints: [
        {
          name: 'ENGINEER_KIT_API',
          endpoint: 'https://staging-api.connectbetter.io',
          custom_header: addFirebaseAuthToken
        }
      ]
    }
  },
  SENTRY: {
    DSN: ''
  },
  GOOGLE: {
    API_KEY: 'AIzaSyBJt4Q5J5pq7jBKPE7KMv3Lsrb3nbqFlwM'
  },
  CRISP: {
    WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40'
  },
  STRIPE: {
    API_KEY: 'pk_test_51LuLuGDGl4W5zqduK8MGiWERthFT51oFudeb8u42QyJchWjuqWEi7Vd6wWk59dUdCXQ4BmTxaGYSdau2ygHJoPui00HhaPYipf'
  },
  CONNECTBETTER_API: {
    WEBSOCKET_HOST: 'wss://staging-api.connectbetter.io',
    CDN_HOST: 'https://staging-cdn.connectbetter.io'
  },
  POSTHOG: {
    API_KEY: 'phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH'
  },
  STUDIO: {
    ENDPOINT: 'https://staging-studio.connectbetter.io'
  },
  CONNECTBETTER: {
    ENDPOINT: 'https://staging-app.connectbetter.io'
  },
  IDENTITY: {
    AVODA_IDENTITY_URL: 'https://staging-identity.avoda.co'
  }
}

const CONFIG_DEV = {
  AWS: {
    API: {
      endpoints: [
        {
          name: 'ENGINEER_KIT_API',
          endpoint: 'http://localhost:4000',
          custom_header: addFirebaseAuthToken
        }
      ]
    }
  },
  SENTRY: {
    DSN: ''
  },
  GOOGLE: {
    API_KEY: 'AIzaSyBJt4Q5J5pq7jBKPE7KMv3Lsrb3nbqFlwM'
  },
  CRISP: {
    WEBSITE_ID: '5c07ecaa-930e-4923-bb42-4bf1fb11ec40'
  },
  STRIPE: {
    API_KEY: 'pk_test_51LuLuGDGl4W5zqduK8MGiWERthFT51oFudeb8u42QyJchWjuqWEi7Vd6wWk59dUdCXQ4BmTxaGYSdau2ygHJoPui00HhaPYipf'
  },
  CONNECTBETTER_API: {
    WEBSOCKET_HOST: 'ws://localhost:4000',
    CDN_HOST: 'https://staging-cdn.connectbetter.io'
  },
  POSTHOG: {
    API_KEY: 'phc_hcvjmgJa6ZuOpsRzx18nEiPuavncFTCN9jNNQVNgVcH'
  },
  STUDIO: {
    ENDPOINT: 'http://localhost:3001'
  },
  CONNECTBETTER: {
    ENDPOINT: 'http://localhost:3000'
  },
  IDENTITY: {
    AVODA_IDENTITY_URL: 'https://localhost:3334'
  }
}

const getConfigForEnvironment = () => {
  if (stage === 'production') {
    return CONFIG_PROD
  } else if (stage === 'staging') {
    return CONFIG_STAGING
  } else {
    return CONFIG_DEV
  }
}

const config = getConfigForEnvironment()

export {
  config
}
