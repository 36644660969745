import currency from 'currency.js'
import { Crisp } from 'crisp-sdk-web'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { isAuthorized } from '../shared/permissions'
import { getOrganizationAdminPolicy } from '../shared/policies'

const getCurrentOrganizationRole = (currentUser) => {
  return currentUser?.organizationRoles?.find(orgRole => orgRole.organization?.id === currentUser.currentOrganizationId)
}

function loadScript (url, callback) {
  const scriptElements = document.querySelectorAll('script[src*=\''.concat(url, '\']'))

  if (scriptElements?.length === 0) {
    const script = document.createElement('script')
    script.type = 'text/javascript'

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null
          callback()
        }
      }
    } else {
      script.onload = () => callback()
    }
    script.src = url
    document.getElementsByTagName('head')[0].appendChild(script)
  } else {
    callback()
  }
}

function openSupportChat () {
  try {
    Crisp.chat.show()
    Crisp.chat.open()
  } catch (error) {
    window.location.href = 'mailto:support@connectbetter.io'
  }
}

function formatAmountAsDollars (amountInCents) {
  return currency(amountInCents, { fromCents: true }).format()
}

function formattedInputAmount (amount) {
  if (!amount) return amount
  const removedCharAmount = amount.replace(/[^\d]/g, '')
  const formatted = currency(removedCharAmount, { fromCents: true }).format()
  if (formatted === '$0.00') {
    return ''
  }
  return formatted
}

function formatAmountAsCents (amount) {
  if (!amount) return amount
  const removedCharAmount = amount.replace(/[^\d]/g, '')
  if (removedCharAmount?.length === 3 && parseInt(removedCharAmount.charAt(0)) === 0) {
    if (parseInt(removedCharAmount.charAt(1)) === 0) {
      return removedCharAmount.slice(2)
    }
    return removedCharAmount.slice(1, 3)
  }
  const dollars = removedCharAmount.slice(0, removedCharAmount?.length - 2)
  const cents = removedCharAmount.slice(removedCharAmount?.length - 2, removedCharAmount?.length)
  return ((parseFloat(dollars) * 100) + parseFloat(cents)).toString()
}

function getCurrentOrganization (currentUser) {
  const { currentOrganizationId } = currentUser || {}
  return currentUser?.organizationRoles?.find(orgRole => orgRole.organization.id === currentOrganizationId)?.organization || null
}

function useSwitchOrganization () {
  const queryClient = useQueryClient()
  return {
    switchOrganization: (organizationId) => switchOrganization(queryClient, organizationId)
  }
}

function switchOrganization (queryClient, organizationId) {
  const oldUser = queryClient.getQueryData(['user'])
  queryClient.setQueryData(['user'], { ...oldUser, currentOrganizationId: organizationId })
  queryClient.invalidateQueries()
}

function getActiveStudioAccessOrganizations (currentUser) {
  return currentUser?.organizationRoles?.filter(orgRole => (orgRole?.status === 'ACTIVE' && orgRole?.organization?.studioAccess === true) && isAuthorized({ policy: getOrganizationAdminPolicy(orgRole?.organization?.id), currentUser })).map(orgRole => orgRole?.organization) || []
}

const searchTokens = (search) => {
  return search.toLowerCase().split('')
    .map((char, index) => {
      return (
        (index === 0 && char) ||
        (index === 1 && search.slice(0, 2)) ||
        (index > 2 && search.slice(index, index + 3))
      )
    })
}

const getSortedContent = ({ searchParms, searchItemDisplay, contents }) => {
  const filteredContents = contents?.filter(content => {
    if (`${content?.name}`.toLowerCase().includes(searchParms.toLowerCase())) {
      return true
    }
    return false
  })

  return relevantContent({ filteredContents, searchParms, searchItemDisplay })
}

const relevantContent = ({ filteredContents, searchParms, searchItemDisplay }) => {
  const scoredContents = filteredContents?.map(content => {
    const name = `${content?.name}`.toLowerCase()
    const searchAsLower = searchParms.toLowerCase()

    let score = 0
    if (name === searchAsLower) {
      score += 100
    }
    if (name.includes(searchAsLower)) {
      score += 50
    }
    for (const token of searchTokens(searchAsLower)) {
      if (name.includes(token)) {
        score++
      }
    }
    return { ...content, score }
  })

  return sortRelevantContent({ contents: scoredContents, searchItemDisplay })
}

const sortRelevantContent = ({ contents, searchItemDisplay }) => {
  const sortedContents = contents
    .filter(content => {
      return content.score >= 1
    })
    .sort((a, b) => {
      return b.score - a.score
    })

  return searchedContent({ sortedContents, searchItemDisplay })
}

const searchedContent = ({ sortedContents, searchItemDisplay }) => {
  if (sortedContents?.length) {
    return sortedContents?.map(content => (
      searchItemDisplay(content)
    ))
  }
  return (
    <div>No items found</div>
  )
}

function useOutsideAlerter ({ ref, setDisplayState }) {
  useEffect(() => {
    function handleClickOutside (event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDisplayState(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const generateCSRF = () => {
  const random = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
  localStorage.setItem('csrf', random)
  return random
}

export {
  getCurrentOrganizationRole,
  loadScript,
  openSupportChat,
  formattedInputAmount,
  formatAmountAsDollars,
  formatAmountAsCents,
  getCurrentOrganization,
  useSwitchOrganization,
  getActiveStudioAccessOrganizations,
  getSortedContent,
  useOutsideAlerter,
  generateCSRF
}
