import { Crisp } from 'crisp-sdk-web'
import { config } from './config'
import { logError } from './logger'

function setupCrisp () {
  try {
    Crisp.configure(config.CRISP.WEBSITE_ID)
  } catch (error) {
    logError(error)
  }
}

export {
  setupCrisp
}
