import { createContext, useContext, useState } from 'react'
import * as Sentry from '@sentry/react'
import { logError, logInfo } from '../shared/logger'
import { useUserQuery } from '../shared/queryHooks'
import { Crisp } from 'crisp-sdk-web'
import { useSearchParams } from 'react-router-dom'
import { useEffectOnce } from '../shared/hooks'
import { getActiveStudioAccessOrganizations, generateCSRF } from '../shared/utilities'
import { isAuthorized } from '../shared/permissions'
import { getOrganizationAdminPolicy } from '../shared/policies'
import { config } from '../shared/config'
import { Buffer } from 'buffer'

export const AuthContext = createContext(null)

const AuthProvider = (props) => {
  const [searchParams] = useSearchParams()
  const organizationId = searchParams.get('organization')
  const token = searchParams.get('t') ?? ''
  const csrf = searchParams.get('csrf') ?? ''
  const [isLoading, setIsLoading] = useState(true)
  const [firebaseToken, setFirebaseToken] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [isSigningOut, setIsSigningOut] = useState(false)

  function setOrganizationId (organizationId) {
    localStorage.setItem('lastOrganizationId', organizationId)
  }

  function isTokenExpired () {
    if (!firebaseToken) return true
    const decodedToken = JSON.parse(Buffer.from(firebaseToken.split('.')[1], 'base64'))
    const expirationDate = new Date(decodedToken.exp * 1000)
    if (expirationDate < new Date()) {
      logInfo('Token expired')
      setFirebaseToken(null)
      localStorage.removeItem('firebaseToken')
      return true
    }
    return false
  }

  function getDefaultOrganizationId (currentUser) {
    if (isAuthorized({ policy: getOrganizationAdminPolicy(currentUser?.currentOrganizationId), currentUser })) {
      return currentUser?.currentOrganizationId
    }
    const defaultId = getActiveStudioAccessOrganizations(currentUser)?.[0]?.id ?? 0
    currentUser.currentOrganizationId = defaultId
    return defaultId
  }

  const { isInitialLoading, isError, data, error } = useUserQuery({ organizationId, options: { enabled: !currentUser && !!firebaseToken && !isTokenExpired() } })
  if (isError) {
    logError(error)
  }

  if (data && !currentUser) {
    try {
      const activeOrDefaultOrganizationId = parseInt(organizationId) || getDefaultOrganizationId(data)
      setOrganizationId(activeOrDefaultOrganizationId)
      Crisp.user.setEmail(data.email)
      Crisp.user.setNickname(`${data.firstName} ${data.lastName}`)
      Sentry.setUser({ authUid: data?.authUid })
      setCurrentUser(data)
    } catch (error) {
      logError(error)
    }
  }

  useEffectOnce(() => {
    signInUserWithToken(token, csrf)
    setIsLoading(false)
  })

  function signInUserWithToken (token, csrf) {
    const localCsrf = localStorage.getItem('csrf')
    if (token && csrf === localCsrf) {
      localStorage.setItem('firebaseToken', token)
      setFirebaseToken(token)
      localStorage.removeItem('csrf')
    } else if (localStorage.getItem('firebaseToken')) {
      setFirebaseToken(localStorage.getItem('firebaseToken'))
    }
  }

  function signOutUser () {
    setIsSigningOut(true)
    localStorage.removeItem('firebaseToken')
    setFirebaseToken(null)
    setCurrentUser(null)
    const csrf = generateCSRF()
    const url = `${config.IDENTITY.AVODA_IDENTITY_URL}/signout/?sourceUrl=${config.STUDIO.ENDPOINT}/sso&csrf=${csrf}`
    window.location.replace(url)
  }

  return (
    <AuthContext.Provider value={{ signOutUser, isLoading, firebaseToken, currentUser, isInitialLoading, isSigningOut }} {...props} />
  )
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
