import { useEffectOnce } from '../../shared/hooks'
import { config } from '../../shared/config'
import { generateCSRF } from '../../shared/utilities'
import LoadingScreen from '../../components/LoadingScreen'

function Login () {
  useEffectOnce(() => {
    const csrf = generateCSRF()
    const url = `${config.IDENTITY.AVODA_IDENTITY_URL}/?sourceUrl=${config.STUDIO.ENDPOINT}/sso&csrf=${csrf}`
    window.location.replace(url)
  })

  return <LoadingScreen />
}

export default Login
