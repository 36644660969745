import { useAuthContext } from '../../context/AuthContext'
import { useProgramsQuery } from '../../shared/queryHooks'
import { logError } from '../../shared/logger'
import { Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { getSortedContent, useOutsideAlerter } from '../../shared/utilities'
import insightsIllustration from '../../assets/insights.svg'
import LoadingScreen from '../../components/LoadingScreen'
import EmptyHero from '../../components/EmptyHero'
import CheckIcon from '@mui/icons-material/CheckRounded'
import SyncIcon from '@mui/icons-material/Sync'
import Logo from '../../components/Logo'
import ProgramSettingsMenu from '../../components/ProgramSettingsMenu'
import { useModal } from '../../context/ModalContext'
import CreateOrEditProgram from '../../modals/CreateOrEditProgram'
import './index.css'

function Programs () {
  const navigate = useNavigate()
  const { makeModal } = useModal()
  const { currentUser } = useAuthContext()
  const { isLoading, isError, error, data: programs } = useProgramsQuery({ publisherOrganizationId: currentUser?.currentOrganizationId, options: { enabled: !!currentUser } })
  const [programSettingsMenu, setProgramSettingsMenu] = useState('')
  const [searchParms, setSearchParms] = useState('')

  if (isLoading) {
    return (<LoadingScreen />)
  }

  if (isError) {
    logError(error)
  }

  const programItemDisplay = (program) => {
    const isPublisher = currentUser?.currentOrganizationId === program?.publisherOrganizationId
    const isApproved = program?.courses?.length > 0 && program?.courses?.some(course => course?.courseStatus === status.PUBLIC || course?.courseStatus === status.PRIVATE)
    const highestCourseStatus = program?.courses?.length > 0
      ? program.courses
        .map(course => course.courseStatus)
        .reduce((highest, current) => {
          return statusPriority[current] < statusPriority[highest] ? current : highest
        }, status.DRAFT)
      : status.DRAFT

    const activeChip = {
      icon: isPublisher && isApproved ? <CheckIcon /> : <SyncIcon />,
      label: <div className='subscribe-chip'>
        {isPublisher ? (isApproved ? highestCourseStatus : 'In Review') : 'Subscribed'}
        {isPublisher && <ProgramSettingsMenu
          programSettingsMenu={programSettingsMenu}
          setProgramSettingsMenu={setProgramSettingsMenu}
          program={program}
          useOutsideAlerter={useOutsideAlerter}
          isPublisher={isPublisher}
        />}
      </div>
    }

    return (
      <div key={program?.id} className='program-card-container' style={{ cursor: isPublisher ? 'pointer' : 'default' }} onClick={() => isPublisher ? navigate(`/programs/${program?.id}`) : null}>
        <div className="chip-container" onClick ={(e) => {
          e.stopPropagation()
          makeModal({
            modal: (
              <CreateOrEditProgram existingProgram={program}/>
            ),
            title: 'Edit Program'
          })
        }}>
          <Chip {...activeChip} size="small" variant="outlined" sx={{ marginRight: '0.5rem' }}/>
        </div>
        <div className="program-metadata-container">
          <Logo logoUrl={program?.publisherLogoURL} name={program?.name} alt={'program logo'}/>
          <div className="program-metadata">
            <div className="program-name">{program?.name}</div>
            <div className="program-publisher-name">{program?.publisherName}</div>
          </div>
        </div>
      </div>
    )
  }

  const handleSearch = (search) => {
    setSearchParms(search)
  }

  const status = {
    DRAFT: 'Draft',
    PRIVATE: 'Private',
    PUBLIC: 'Public'
  }

  const statusPriority = {
    [status.PUBLIC]: 1,
    [status.PRIVATE]: 2,
    [status.DRAFT]: 3
  }

  const action = [{
    onClick: () => makeModal({
      modal: (
        <CreateOrEditProgram/>
      ),
      title: 'Create Program'
    }),
    actionTitle: 'Create Program'
  }]

  const generateProgramDisplay = (
    programs
      ?.sort((a, b) => {
        const statusOrder = {
          PUBLIC: 0,
          PRIVATE: 1,
          DRAFT: 2
        }
        return statusOrder[a?.programStatus] - statusOrder[b?.programStatus]
      })
      .map(program => {
        return programItemDisplay(program)
      })
  )
  return (
    <>
      <div className='program-header-container'>
        <div className="marketing-title-header">Programs</div>
        <div className="button secondary program-button" onClick={() => makeModal({
          modal: (
            <CreateOrEditProgram/>
          ),
          title: 'Create Program'
        })}>Create Program</div>
      </div>
      <div className="program-search">
        <input
          className="program-input"
          type="text"
          placeholder="Search Programs..."
          onChange={(e) => handleSearch(e.target.value)}
          value={searchParms}
        />
      </div>
      <div className="programs-container">
        {programs?.length > 0
          ? <div className="program-wrapper">
              {searchParms ? getSortedContent({ searchParms, searchItemDisplay: programItemDisplay, contents: programs }) : generateProgramDisplay}
            </div>
          : <EmptyHero
              image={insightsIllustration}
              title="Programs"
              data-testid="insights-empty-hero"
              description="You don't have any programs yet. Once you have created a program, analytics will appear here."
              action={action}
            />
        }
      </div>
    </>
  )
}

export default Programs
