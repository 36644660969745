import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from '@mui/material'
import { useCreateCourseMutation, useUpdateCourseMutation } from '../../shared/queryHooks'
import { PuffLoader } from 'react-spinners'
import { useAuthContext } from '../../context/AuthContext'
import { getCurrentOrganization } from '../../shared/utilities'
import './index.css'

function PublishCourse ({ clearModal, courseVersionData, isInitialDraft }) {
  const navigate = useNavigate()
  const { currentUser } = useAuthContext()
  const currentOrganization = getCurrentOrganization(currentUser)
  const createCourse = useCreateCourseMutation()
  const updateCourse = useUpdateCourseMutation()

  const publishTypes = {
    private: 'PRIVATE',
    public: 'PUBLIC',
    draft: 'DRAFT'
  }

  const [course, setCourse] = useState(courseVersionData)

  useEffect(() => {
    setCourse(courseVersionData)
  }, [])

  function handleChange ({ attribute, value }) {
    setCourse(prev => ({ ...prev, [attribute]: value }))
  }

  async function handleSave () {
    if (isInitialDraft) {
      await createCourse.mutateAsync(course)
    } else {
      await updateCourse.mutateAsync(course)
    }
    navigate(`/programs/${courseVersionData?.programId}`)
    clearModal()
  }

  return (
    <>
      <div className="publish-content">
        {courseVersionData?.courseStatus === publishTypes.draft && <div>
          <div className="publish-title"><Checkbox checked={course.courseStatus === publishTypes.draft} onClick={() => handleChange({ attribute: 'courseStatus', value: publishTypes.draft })}/>Save Draft</div>
          <div className="publish-message">A draft course version will only be saved in the ConnectBetter Studio. It will not be
            publicly available on the ConnectBetter marketplace. This course version will not automatically be added to your organization&apos;s
            courses.
            </div>
        </div>}
        <div>
          <div className="publish-title"><Checkbox checked={course.courseStatus === publishTypes.private} onClick={() => handleChange({ attribute: 'courseStatus', value: publishTypes.private })}/>Publish Private</div>
          <div className="publish-message">A privately published course version will only be visible by your organization. It will not be
            publicly available on the ConnectBetter marketplace. This course version will automatically be added to your organization&apos;s
            courses and will be available for immediate use.
            </div>
        </div>
        {currentOrganization?.isContentCreator && <div>
          <div className="publish-title"><Checkbox checked={course.courseStatus === publishTypes.public} onClick={() => handleChange({ attribute: 'courseStatus', value: publishTypes.public })}/>Publish Public</div>
          <div className="publish-message">A publicly published course version will be available on the ConnectBetter marketplace for any
            organization to use. This course will go through a review process by the ConnectBetter team before being made publicly available,
            however this course will automatically be added to your organization&apos;s courses and will be available for immediate use.
            <div className="publish-footnote"><sup>*</sup>You will not be able to make changes to this course during the review process.</div>
          </div>
        </div>}
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button" onClick={() => handleSave()}>{(createCourse.isLoading || updateCourse.isLoading) ? <PuffLoader color="#fff" size={21} /> : 'Publish'}</div>
      </div>
    </>
  )
}

export default PublishCourse
