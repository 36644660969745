import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import Radio from '@mui/material/Radio'
import { courseTypes } from '../../shared/enums'
import './index.css'

function CourseType ({ programId }) {
  const [courseType, setCourseType] = useState(courseTypes.selfPaced)
  const navigate = useNavigate()
  const { clearModal } = useModal()

  function handleNavigate () {
    navigate(`/courses/draft?programId=${programId}&type=${courseType}`)
    clearModal()
  }

  return (
    <>
      <div>
        <div className="type-container" onClick={() => setCourseType(courseTypes.selfPaced)}>
          <Radio
            checked={courseType === courseTypes.selfPaced}
            value={courseType.selfPaced}
            name="radio-buttons"
            inputProps={{ 'aria-label': 'Self-Paced' }}
          />
          <div>Self-Paced: This course allows learners to progress at their own pace, providing flexibility to complete the material on their own schedule.</div>
        </div>
        <div className="type-container" onClick={() => setCourseType(courseTypes.structured)}>
          <Radio
            checked={courseType === courseTypes.structured}
            value={courseType.structured}
            name="radio-buttons"
            inputProps={{ 'aria-label': 'Structured' }}
          />
          <div>Structured: This course offers a more guided experience, with the publisher setting specific dates for exercises and assignments to be completed.</div>
        </div>
      </div>
      <div className="modal-footer">
        <div className="button secondary" onClick={() => clearModal()}>Cancel</div>
        <div className="button" onClick={() => handleNavigate()}>Continue</div>
      </div>
    </>
  )
}

export default CourseType
